import React, { useState } from 'react';

import 'typeface-roboto-condensed'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faArrowLeft, faArrowRight, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   // Link
// } from "react-router-dom";

import Navbar from './components/Navbar'
import Home from './components/Home'
import Menu from './components/Menu'
import Jogos from './components/Jogos'
import Memoria from './components/Memoria'
import QuebraCabeca from './components/QuebraCabeca'
import Paineis from './components/Paineis'
import Lista from './components/Lista'
import Maquete from './components/Maquete'
import Descricao from './components/Descricao'
import Tour from './components/Tour'
import PassadoPresente from './components/PassadoPresente'
import Audioguia from './components/Audioguia'
import FichaTecnica from './components/FichaTecnica'

import './global.css'
import './App.css'

import Brinquedos from './content/brinquedos.json'
import Moveis from './content/moveis.json'
import SalaEpoca from './content/sala-epoca.json'
import Quadros from './content/quadros'
import QuadrosDemontrativos from './content/quadros-demonstrativos.json'
import QuadrosParietais from './content/quadros-parietais.json'
import PaineisFormatura from './content/paineis.json'
import Simbolos from './content/simbolos-paineis.json'

library.add(faHome, faArrowLeft, faArrowRight, faInfo, faInfoCircle)

const languages = [
  {id:'pt', description:"Português", image:'/content/languages/1_portugues.png'},
  {id:'en', description:"English", image:'/content/languages/1_ingles.png'},
  {id:'es', description:"Espanhol", image:'/content/languages/1_espanhol.png'}
]

function App() {
  const [page, setPage] = useState('home')
  const [title, setTitle] = useState('')
  const [language, setLanguage] = useState('pt')
  const [showNav, setShowNav] = useState(true)

  return (
    <div className={`app ${showNav?'with-nav':''}`}>
      {showNav && <Navbar title={title} language={language} setLanguage={setLanguage} languages={languages} setPage={setPage}></Navbar>}        

      {page==='home' && <Home setTitle={setTitle} setShowNav={setShowNav} language={language} setLanguage={setLanguage} languages={languages} setPage={setPage}></Home>}

      {page==='menu' && <Menu setTitle={setTitle} language={language} setPage={setPage}></Menu>}

      {page==='tour' && <Tour setTitle={setTitle} language={language} setPage={setPage}></Tour>}

      {page==='jogos' && <Jogos setTitle={setTitle} language={language} setPage={setPage}></Jogos>}

      {page==='jogos-memoria' && <Memoria setTitle={setTitle} language={language} setPage={setPage}></Memoria>}

      {page==='jogos-quebracabeca' && <QuebraCabeca setTitle={setTitle} language={language} setPage={setPage}></QuebraCabeca>}

      {page==='paineis-descricao' && <Descricao setTitle={setTitle} content={PaineisFormatura} back="menu" language={language} setPage={setPage}></Descricao>}

      {page==='paineis-simbolos' && <Lista setTitle={setTitle} lista={Simbolos} back="paineis-descricao" language={language} setPage={setPage}></Lista>}

      {page==='paineis' && <Paineis setTitle={setTitle} content={PaineisFormatura} language={language} setPage={setPage}></Paineis>}

      {page==='brinquedos' && <Descricao setTitle={setTitle} content={Brinquedos} back="menu" language={language} setPage={setPage}></Descricao>}

      {page==='brinquedos-itens' &&  <Lista setTitle={setTitle} lista={Brinquedos} back="brinquedos" language={language} setPage={setPage}></Lista>}

      {page==='moveis' &&  <Descricao setTitle={setTitle} content={Moveis} back="menu" language={language} setPage={setPage}></Descricao>}
        
      {page==='moveis-itens' && <Lista setTitle={setTitle} lista={Moveis} back="moveis" language={language} setPage={setPage}></Lista>}

      {page==='saladeepoca' && <Descricao setTitle={setTitle} content={SalaEpoca} back="menu" language={language} setPage={setPage}></Descricao>}

      {page==='quadros' && <Descricao setTitle={setTitle} content={Quadros} back="saladeepoca" language={language} setPage={setPage}></Descricao>}

      {page==='quadrosdemonstrativos' && <Lista setTitle={setTitle} lista={QuadrosDemontrativos} back="quadros" language={language} setPage={setPage}></Lista>}

      {page==='quadrosparietais' && <Lista setTitle={setTitle} lista={QuadrosParietais} back="quadros" language={language} setPage={setPage}></Lista>}

      {page==='maquete' && <Maquete setTitle={setTitle} language={language} setPage={setPage}></Maquete>}

      {page==='passadoepresente' && <PassadoPresente setTitle={setTitle} language={language} setPage={setPage}></PassadoPresente>}

      {page==='audioguia' && <Audioguia setTitle={setTitle} language={language} setPage={setPage}></Audioguia>}

      {page==='fichatecnica' && <FichaTecnica setTitle={setTitle} language={language} back="menu" setPage={setPage}></FichaTecnica>}
    </div>
  
  )
}

export default App;
