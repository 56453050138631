import React from 'react'


function Card({theme, card, flipCard}){
    // const [isFlipped, setFlipped] = useState(card.isFlipped)

    // function flipCard(){
    //     setFlipped(!isFlipped)

    //     setTimeout(()=>{
    //         setFlipped(false)
    //     },1200)
    // }

    return (
        <div className="flip-card">
            <div className={`flip-card-inner ${card.isFlipped?'flipped':''}`}>
                <div className="flip-card-front">
                    <img className="flip-card-image-front" src={`./content/${theme.id}/${card.image}`} alt="back"></img>
                </div>

                <div className="flip-card-back"  onClick={()=>{flipCard(card)}}>
                    <img className="flip-card-image-back" src="./content/verso-carta.jpg" alt="back"></img>
                </div>
            </div>
        </div>

    )
}
 
export default Card
