import React, { useState, useEffect, useRef, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import uuid from 'uuid/v4'

import useResize from '../../hooks/useResize'
import useMousePosition from '@react-hook/mouse-position'

import Imagens from '../../content/quebra-cabeca.json'

import getPieces from './pieces'
import Piece from './Piece'

import './styles.css'

import formataTempo from '../../utils/formataTempo'
import Lista from '../../components/Lista'

// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// import disableScroll from 'disable-scroll';

const texts = {
    title:{
        pt:"Quebra Cabeça",
        en:"Memory Game",
        es:"Juego de Memoria",
    },
    newgame:{
        pt:"Novo jogo",
        en:"New game",
        es:"Nuevo juego",
    },
    congratulations:{
        pt:"Parabéns!",
        en:"Congratulations!",
        es:"¡Felicidades!",
    },
    time:{
        pt:"Você finalizou o jogo em",
        en:"You finished the game in",
        es:"Usted terminó el juego en",
    },
    image:{
        pt:"imagem",
        en:"image",
        es:"imagen",
    },
    show:{
        pt:"Mostrar",
        en:"Show",
        es:"Mostrar",
    },
    hide:{
        pt:"Ocultar",
        en:"Hide",
        es:"Esconder",
    }
}

const options = {
    tol:20,
    columns: 6,
    rows: 4     
} 

function shuffle(array) {    
    return array.sort(() => Math.random() - 0.5);
}

function startPosition({rows, columns}, image, pieces){
    let ids = []
    for (let i=0;i<pieces.length;i++){
        ids.push(i)
    }
    ids = shuffle(ids)

    let groups = []

    let i = 0;
    for (let g=0;g<columns;g++){
        let group = {
            pieces:[]
        }
        for(let h=0;h<rows;h++){
            group.pieces.push(pieces[ids[i]])
            i++
        }
        group.width = Math.max(...group.pieces.map(piece=>piece.width))

        groups.push(group)
    }

    let placeholders = []

    let dx = (image.width / rows) * 1.7
    let dy = (image.height / columns) * 1.7



    for (let i=0; i<rows; i++){
        for (let j=0; j<columns; j++){
            placeholders.push({
                x: j * dx,
                y: i * dy,
                w: dx,
                h: dy
            })
        }        
    }
    
    return groups
}

function norm(v){
    return (v.x**2+v.y**2)**0.5
}

function verificaProximo(pieces, piece1, selectedGroup){
    let tol = options.tol
    let {dx,dy,i,j,proximo} = piece1
    
    let proximas = proximo.horizontal.concat(proximo.vertical).map(id=>pieces.find(p2=>p2.id===id))

    if(selectedGroup){
        proximas = proximas.filter(p=>!selectedGroup.pieces.some(id=>id===p.id))
    }
    
    let piece2 = proximas.find(piece2=>{
        let a = {x:dx*(piece2.j-j), y:dy*(piece2.i-i)}
        let c = {x:(piece2.position.x-piece1.position.x), y:(piece2.position.y-piece1.position.y)}
        let b = {x:c.x-a.x, y:c.y-a.y}
        return  norm(b) <= tol      
    })

    return piece2    
}

function verificaPecas(piece1, piece2){
    let tol = options.tol
    // console.log(piece1,piece2)
    // let proximas = piece1.proximo.horizontal.concat(piece1.proximo.vertical).map(id=>pieces.find(p2=>p2.id===id))

    // if(selectedGroup){
    //     proximas = proximas.filter(p=>!selectedGroup.pieces.some(id=>id===p.id))
    // }    

    let di = piece2.i-piece1.i
    let dj = piece2.j-piece1.j
    let dx = piece2.position.x-piece1.position.x
    let dy = piece2.position.y-piece1.position.y

    let a = {x:piece1.dx*di, y:piece1.dy*dj}
    let c = {x: dx, y: dy}
    let b = {x:c.x-a.x, y:c.y-a.y}
    return  norm(b) <= tol      

}

function verificaGrupos(group1, group2){
    return group1.pieces.find(piece1 => group2.pieces.find(piece2=>verificaPecas(piece1, piece2)))
}

function verificaGrupoProximo(group1, groups, pieces){
    
    group1 = {...group1, pieces:group1.pieces.map(id=>pieces.find(p=>id===p.id))}
    groups = groups.map(g => ({...g, pieces:g.pieces.map(id=>pieces.find(p=>id===p.id))}))

    // console.log('group1', group1, 'groups', groups)

    return groups.find(group2 => verificaGrupos(group1, group2))
    
        
}

function QuebraCabeca({setTitle, language, setPage}){
    const ref2 = useRef()
    // const [initial, setInitial] = useState(null)
    const [piecesInitialPosition, setPiecesInitialPosition] = useState(true)
    const [showPieces, setShowPieces] = useState(false)
    const [grupos, setGrupos] = useState([])
    const [selected, setSelected] = useState(null)    
    const [mousePosition, ref] = useMousePosition(0,0,60);
    const {width, height} = useResize(ref2)
    const [elapsed, setElapsed] = useState(0)
    const [pieces, setPieces] = useState([])
    // eslint-disable-next-line
    const [image, setImage] = useState(null)
    const [mostrarImagem, setMostrarImagem] = useState(false)
    const paused = useRef(true)
    // eslint-disable-next-line 
    const [isTouch, setIsTouch] = useState(true)
    // const [lastTouch, setLastTouch] = useState(null)
    // useEffect(()=>{
    //     window.blockMenuHeaderScroll = true;
    // })

    useEffect(()=>{
        setTitle('Quebra-Cabeça')
        
        // eslint-disable-next-line
    },[])

    

    useEffect(()=>{
        let interval = setInterval(()=>{
            if(!paused.current){
                setElapsed(elapsed=>elapsed+1)
            }
        },1000)

        return ()=>{clearInterval(interval)}
    },[])

    
    const completed = useMemo(()=>{
        return grupos.length===1 && grupos[0].pieces.length===options.rows*options.columns
    },[grupos])

    useEffect(()=>{
        if(completed){
            paused.current = true
        }
    },[completed])

    useEffect(()=>{
        if(image){
            let w = ref2.current.clientWidth
            let h = ref2.current.clientHeight
            let pieces = getPieces(options, image)//.splice(5,1))
            ref(ref2.current)
            pieces.forEach(piece=>{
                piece.position.x = w/2 - image.width/2 + piece.j*piece.dx
                piece.position.y = h/2 - image.height/2 + piece.i*piece.dy
            })
            setPieces(pieces)       
        }
        // eslint-disable-next-line
    },[image])

    function selecionarImagem({imagem}){
        setImage({
            x: 0,
            y: 0,
            width: 750,
            height: 500,
            dx:0,
            dy:0,
            imagem,
            arquivo:imagem
        })
        setPiecesInitialPosition(true)
        setGrupos([])
        setMostrarImagem(false)
        setElapsed(0)
        paused.current = false
    }


    

    useEffect(()=>{
        if(piecesInitialPosition && pieces.length>0 && pieces.every(p=>p.width!==0 && p.height!==0)){
            setInitialPosition()
        }
        // eslint-disable-next-line
    },[pieces])

    function setInitialPosition(){
        let groups = startPosition(options, image, pieces)            
            let margin = 10
            let x = margin
            let soma = 0
            groups.filter((group,index)=>index>=groups.length/2).forEach(group=>{
                soma+=group.width+margin
            })
            groups.forEach((group,index)=>{                
                let y = margin                
                if(index===groups.length/2){
                    x = width - soma
                }  
                group.pieces.forEach(p=>{ 
                    let dx =  p.position.x - p.bounding.x
                    let dy =  p.position.y - p.bounding.y + 100          
                    setPosition(p.id, x + dx, y + dy)
                    y+= p.height + margin
                })
                x+=group.width + margin
            })
            setPiecesInitialPosition(false)
            setShowPieces(true)
    }

    function addPiecesToGroup(id, ids){
        setGrupos((grupos)=>grupos.map(g=>{
            if(g.id===id){
                return {...g, pieces:[...g.pieces, ...ids]}
            }
            else{
                return g
            }
        }))
    }

    function createGroup(pieces){
        setGrupos([...grupos, {id:uuid(), pieces}])
    }


    function removeGroup(id){
        setGrupos((grupos)=>grupos.filter(g=>g.id!==id))
    }

    function findGroupByPiece(pieceId){
        return grupos.find(g => g.pieces.some( id => id===pieceId))
    }

    function onTouchEnd(piece){
        onMouseDown(piece)
    }

    function onMouseDown(p, x=0, y=0){
        if(!selected){
            let gr = findGroupByPiece(p.id) 
            let dx = - x + p.position.x
            let dy = - y + p.position.y

            let distances = {}
            let grpieces = gr ? pieces.filter(p=> gr.pieces.some(id=>id===p.id)) : []
            grpieces.forEach(pc=>distances[pc.id]={
                dx: - x + pc.position.x,
                dy: - y + pc.position.y
            })

            setSelected({
                piece:p.id, 
                dx: dx,
                dy: dy,
                group: gr ? {
                    id:gr.id,
                    pieces: grpieces.map(({id})=>({id})) ,  
                    distances                
                } : null
            })      
        }else{            
            let selectedPiece = pieces.find(p => p.id===selected.piece)
            let selectedGroup = findGroupByPiece(selectedPiece.id)
            // console.log('s',selected.piece)
            let nearPiece = verificaProximo(pieces, selectedPiece, selectedGroup)

            // console.log('nearPiece', nearPiece)

            if(nearPiece){
                let nearGroup = findGroupByPiece(nearPiece.id)
                // console.log('selectedPiece', selectedPiece,'selectedGroup',selectedGroup)
                // console.log('nearPiece', nearPiece,'nearGroup',nearGroup)

                if(!selectedGroup && !nearGroup){
                    // console.log('create group')
                    createGroup([selectedPiece.id, nearPiece.id])
                    // adjust selected piece position
                    let {dx,dy} = selectedPiece
                    let di = selectedPiece.i-nearPiece.i
                    let dj = selectedPiece.j-nearPiece.j
                    selectedPiece.position = {x:nearPiece.position.x+dj*dx,y: nearPiece.position.y+di*dy}
                    setPosition(selectedPiece.id, selectedPiece.position.x, selectedPiece.position.y)
                }

                if(selectedGroup && !nearGroup){
                    // console.log('add near to selected group')
                    addPiecesToGroup(selectedGroup.id, [nearPiece.id])
                    // adjust near piece position
                    let {dx,dy} = selectedPiece
                    let di = -selectedPiece.i+nearPiece.i
                    let dj = -selectedPiece.j+nearPiece.j
                    nearPiece.position = {x:selectedPiece.position.x+dj*dx,y: selectedPiece.position.y+di*dy}
                    setPosition(nearPiece.id, nearPiece.position.x, nearPiece.position.y)
                }

                if(!selectedGroup && nearGroup){
                    // console.log('add selected to near group')
                    addPiecesToGroup(nearGroup.id, [selectedPiece.id])
                    // adjust selected piece position
                    let {dx,dy} = selectedPiece
                    let di = selectedPiece.i-nearPiece.i
                    let dj = selectedPiece.j-nearPiece.j
                    selectedPiece.position = {x:nearPiece.position.x+dj*dx,y: nearPiece.position.y+di*dy}
                    setPosition(selectedPiece.id, selectedPiece.position.x, selectedPiece.position.y)
                }

                if(selectedGroup && nearGroup){
                    addPiecesToGroup(nearGroup.id, selectedGroup.pieces.map(id=>id))
                    removeGroup(selectedGroup.id)
                    // adjust selected pieces position 
                    selectedGroup.pieces.forEach(id=>{
                        let {dx,dy,i,j} = pieces.find(p=>p.id===id)
                        let di = i-nearPiece.i
                        let dj = j-nearPiece.j
                        let position = {x:nearPiece.position.x+dj*dx,y: nearPiece.position.y+di*dy}
                        setPosition(id, position.x, position.y)
                    })
                }
            }else if(selectedGroup){
                let nearGroups = grupos.filter(g=>g.id!==selectedGroup.id)
                let nearGroup = verificaGrupoProximo(selectedGroup, nearGroups, pieces)

                if(nearGroup){
                    addPiecesToGroup(nearGroup.id, selectedGroup.pieces.map(id=>id))
                    removeGroup(selectedGroup.id)
                    let nearPiece = nearGroup.pieces[0]
                    // adjust selected pieces position 
                    selectedGroup.pieces.forEach(id=>{
                        let {dx,dy,i,j} = pieces.find(p=>p.id===id)
                        let di = i-nearPiece.i
                        let dj = j-nearPiece.j
                        let position = {x:nearPiece.position.x+dj*dx,y: nearPiece.position.y+di*dy}
                        setPosition(id, position.x, position.y)
                    })
                }
            }
            setSelected(null)
            // setLastTouch(null)
        }        
    }


    useEffect(()=>{
        if(selected && mousePosition.x && mousePosition.y){
            let selectedGroup = findGroupByPiece(selected.piece)
            if(selectedGroup){           
                selectedGroup.pieces.forEach((id)=>{
                    setPosition(id, mousePosition.x+selected.group.distances[id].dx, mousePosition.y+selected.group.distances[id].dy) 

                    // if(lastTouch && mousePosition.x && mousePosition.y){                       
                    //     setPosition(id, mousePosition.x + lastTouch.dx , mousePosition.y + lastTouch.dy)
                    // }else{
                    //     
                    // }
                     
                })
            }else{
                // let {id, position} = pieces.find(p=>p.id===selected.piece)

                setPosition(selected.piece, mousePosition.x+selected.dx, mousePosition.y+selected.dy)
                // if(lastTouch && mousePosition.x && mousePosition.y){

                //     setPosition(id, mousePosition.x + lastTouch.dx, mousePosition.y + lastTouch.dy)
                // }else{
                //     setPosition(id, position.x+dx, position.y+dy)
                // }
                
            }
        }

        // eslint-disable-next-line
    },[mousePosition])

    function setPosition(id, x, y){
        setPieces((pieces)=>pieces.map(piece=>{
            if(piece.id===id){
                return {...piece, position:{x,y}}
            }
            else{
                return piece
            }
        }))
    }

    function setSize(id, width, height, x, y){        
        setPieces((pieces)=>pieces.map(p=>{
            if(p.id===id){
                let bounding = {x,y,width, height}
                return {...p, width, height, bounding}
            }else{
                return p
            }
        }))
    }

    const render = {

            novoJogo(){
                return (
                    <Lista setTitle={setTitle} lista={{id:"predio",nome:texts.title, itens:Imagens.map(img=>({...img, arquivo:img.id+'-m.jpg'}))}} selecionar={selecionarImagem} back="jogos" language={language} setPage={setPage}></Lista>
                )
            },
        
            play(){
                return (
                    <>                  
                        <div className="quebracabeca">
                            <svg ref={ref2} image={image} width={'100%'} height={'100%'} onMouseMove={()=>setIsTouch(false)}> 
                                <rect x={(width-image.width)/2} y={(height-image.height)/2} width={image.width} height={image.height} stroke="#111" fill="none" />      
                                {pieces.map(piece => <Piece key={piece.id} piece={piece} image={image} onMouseDown={onMouseDown} onTouchEnd={onTouchEnd} setSize={setSize} show={showPieces} ></Piece>)}
                                
                                {mostrarImagem && <image x={(width-image.width)/2} y={(height-image.height)/2} width={image.width} height={image.height} href={`/content/predio/${image.arquivo}`} />  }
        
                                {completed && (
                                    <>
                                    <rect x={0} y={0} width={width} height={height} fill="black" style={{fillOpacity: 0.6}}></rect>
                                    <text x={width/2} y={(height/2)-18} fontSize={36} fill="white" textAnchor="middle" alignmentBaseline="middle">{texts.congratulations[language]}</text> 
                                    <text x={width/2} y={(height/2)+18} fontSize={22} fill="white" textAnchor="middle" alignmentBaseline="middle">{texts.time[language]} {formataTempo(elapsed)}</text>
                                    </>
                                    
                                )}
                                {/* {<text x={10} y={30}>{width}x{height} {mousePosition.x},{mousePosition.y} {selected && selected.piece} image:{JSON.stringify(image)}   </text>} */}
                                {/* <circle cx={mousePosition.x} cy={mousePosition.y} r="40"  strokeWidth="3" stroke="red" fill="none"/> */}

                            </svg>
                    
                        </div>
                        <footer className="footer">
                            <div>
                                <button className="btn" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home" /></button>
                            </div>
                            <div>                    
                                <button className="btn mr" onClick={()=>{setImage(null);paused.current=true}}>{texts.newgame[language]}</button>
                                <button className="btn" onClick={()=>{setMostrarImagem(!mostrarImagem)}}>{mostrarImagem?texts.hide[language]:texts.show[language]} {texts.image[language]}</button>
                            </div>
                            <div className="tempo">
                                {formataTempo(elapsed)}
                            </div>
                        </footer>           
                    </>
                )
            }
        
    }

    


    
    if(image){
        return render.play()
    }else{
        return render.novoJogo()
    }
}
 
export default QuebraCabeca
