import React, { useState, useEffect, useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.css'

import { useRef } from 'react';

// const uuid = require('uuid/v4')

 
function getSize(screen, image){

    let maxWidth = screen.width,
        maxHeight = screen.height;
    let imgWidth = image.width,
        imgHeight = image.height;

    let widthRatio = maxWidth / imgWidth,
        heightRatio = maxHeight / imgHeight;
    let bestRatio = Math.min(widthRatio, heightRatio);

    return {
        width: imgWidth * bestRatio,
        height: imgHeight * bestRatio
    }
}

function Paineis({setTitle, content, language, setPage}){
    const [listaFormandos, setListaFormandos] = useState(false)
    const [paineis, setPaineis] = useState([])
    const [painel, setPainel] = useState(null)
    const [formando, setFormando] = useState(null)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    // const [svg, setSvg] = useState({wdith:0,height:0})
    // const ref = useRef(false)
    const refSvg = useRef(false)
   

    let image = useMemo(()=>{
        if(painel){
            let {width:w, height:h} = getSize({width, height},{width:painel.w, height:painel.h})
            return {
                x:(width-w)/2,
                y:(height-h)/2,
                width:w,
                height:h
            }
        }
    },[width, height, painel])

    const navigation = useMemo(() => {
        if(painel && formando){
            let index = painel.formandos.indexOf(formando)
            let previous = index>0
            let next = index<painel.formandos.length-1

            return { index, next, previous }
        }else{
            return {
                index:0,
                previous:true,
                next:true
            }
        }
        
    }, [painel, formando]);

    useEffect(()=>{
        
        setPaineis(content.itens)
        // setPainel(content.itens[0])
        // setFormando(content.itens[0].formandos[0])
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        let title = {
            pt:"Painéis de Formatura",
            en:"Graduation Panels",
            es:"Paneles de Graduación"
        }
        setTitle(title[language])
        // eslint-disable-next-line
    },[language])


    useEffect(()=>{
        if(refSvg.current){
            let {width, height} = refSvg.current.getBoundingClientRect()
            setWidth(width)
            setHeight(height)
        }
    },[painel, formando])

    useEffect(()=>{        
        function updateSize() {
            if(refSvg.current){
                let {width, height} = refSvg.current.getBoundingClientRect()
                setWidth(width)
                setHeight(height)
            }
        }
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    },[])


    function next(tipo){
       
        if(tipo==='formando'){
            setFormando(painel.formandos[navigation.index+1])
        }else{
            // setPainel(paineis[navigation.index+1])
        }
        
    }

    function previous(tipo){
        if(tipo==='formando'){
            setFormando(painel.formandos[navigation.index-1])
        }else{
            // setPainel(paineis[navigation.index-1])
        }
    }

    function listaPaineis(){
        setFormando(null)
        setPainel(null)
    }

    function selecionarPainel(painel){
        setPainel(painel)
        setFormando(painel.formandos[0])
    }

  
    const render = {
        listaPaineis(){
            return (
                <>
                    <div id="lista-paineis">      
                        <div id="itens-lista-paineis">     
                            {paineis.map(painel=>(
                                <div key={painel.id} className="item-lista-paineis" onClick={()=>selecionarPainel(painel)}>
                                    <div className="img">
                                        <img className="img-painel" src={`./content/paineis/${painel.id}/${painel.id}_miniatura.png`} alt="painel"></img>
                                    </div>
                                    <div className="descricao-item-lista-paineis">
                                        <span>{painel.descricao1[language]}<br/>
                                        <strong>{painel.descricao2[language]}</strong></span>
                                    </div>
                                    <center><span className="ano-item-lista-paineis">{painel.ano}</span></center>
                                </div>      
                            ))}                
                        </div> 
                    </div>
                    <footer className="footer">
                        <div>
                            <button className="btn mr" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home" /></button>
                            <button className="btn" onClick={()=>setPage('paineis-descricao')}><FontAwesomeIcon icon="arrow-left" /></button>
                        </div> 
                        <div>
                        </div>   
                        <div>
                            <button className="btn" onClick={()=>{setListaFormandos(true)}}>Lista de Formandos</button>
                        </div>                                         
                    </footer>
                </>
            )
        },
        listaFormandos(){
            return (
                <>
                    <div id="lista-formandos">      
                        { paineis.map( painel => (
                            <div key={painel.id} className="item-lista-formandos">
                                <h3>{painel.descricao1[language]}<br/>{painel.descricao2[language]}<br/>{painel.ano}</h3>
                                {
                                    painel.formandos.map(formando=>(
                                        <div className="formando-lista-formandos" onClick={()=>{setPainel(painel); setFormando(formando); setListaFormandos(false)}}>
                                            <img className="img-formando-lista-formandos" src={`./content/paineis/${painel.id}/${formando.imagem}`} alt="formando"></img>
                                            <p key={formando.nome} className="texto-formando-lista-formandos"><strong>{formando.nome}</strong> <br/>{formando.complemento[language]}</p>
                                        </div>                                        
                                    ))
                                }
                            </div>
                        ))}

                    </div>
                    <footer className="footer">
                        <div>
                            <button className="btn mr" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home" /></button>
                            <button className="btn" onClick={()=>setPage('paineis-descricao')}><FontAwesomeIcon icon="arrow-left" /></button>
                        </div>  
                        <div>
                        </div>
                        <div>
                            <button className="btn" onClick={()=>{setListaFormandos(false); setFormando(null); setPainel(null)}}>Lista de Painéis</button>
                        </div>                                           
                    </footer>
                </>
            )
        },
        detalheFormando(){
            return (
                <>     
                    <div id="paineis">
                        
                        <div className="painel">                        
                            <svg ref={refSvg}  width="100%" height="100%">                          
                                <image href={`/content/paineis/${painel.id}/${painel.id}.png`} alt="painel" x={image.x} y={image.y} width={image.width} height={image.weight}></image>
                                {/* <rect x={image.x} y={image.y} width={image.width} height={image.height} stroke="red" strokeWidth={2} fill="none"></rect>
                                <rect x={0} y={0} width={width} height={height} stroke="blue" strokeWidth={2} fill="none"></rect>                         */}
                                <image href="./content/seta.png" x={(image.x+formando.x*image.width)-87} y={(image.y+formando.y*image.height)}></image>
                            </svg>                        
                        </div>
    
                        <div className="formando">
                            <div className="formando-foto">
                                <img className="img-formando" src={`./content/paineis/${painel.id}/${formando.imagem}`} alt="formando"></img>
                            </div>
                            <div className="controle-formando">
                                <div>
                                    {navigation.previous && <button className="btn" onClick={()=>previous('formando')}><FontAwesomeIcon icon="arrow-left"/></button>}
                                </div>
    
                                <div className="descricao-formando">
                                    <h2>{formando.nome}</h2>
                                    <h3>{formando.complemento[language]}</h3>
                                </div>
    
                                <div>
                                    {navigation.next && <button className="btn" onClick={()=>next('formando')}><FontAwesomeIcon icon="arrow-right"/></button>}
                                </div>
                            </div>                        
                        </div>
    
                    </div>
    
    
                    <footer className="footer">
                        <div>
                            <button className="btn mr" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home" /></button>
                            <button className="btn" onClick={listaPaineis}><FontAwesomeIcon icon="arrow-left" /></button>
                        </div>  
                        <div>
                        </div>  
                        <div>
                            <button className="btn" onClick={()=>setListaFormandos(true)}>Lista de Formandos</button>
                        </div>               
                    </footer>          
                </>            
            )
        }
    }

    if(listaFormandos){
        return render.listaFormandos()
    }
    else if(painel && formando){
        return render.detalheFormando()
    }  
    else{
        return render.listaPaineis()
    }
}
 
export default Paineis
