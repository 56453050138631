import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.css'



function Menu({setTitle, language, setPage}){

    useEffect(()=>{
        setTitle('')
        // eslint-disable-next-line
    },[])

    const itens = [
        {id: 1, title: {"pt":"Tour Virtual", "en":"Virtual Tour", "es":"Visitación Virtual"}, link:"tour", image:"menu-1.jpg"},
        {id: 2, title: {"pt":"Painéis de Formatura", "en":"Graduation Panels", "es":"Paneles de Graduación"}, link:"paineis-descricao", image:"menu-2.jpg"},
        {id: 3, title: {"pt":"Móveis Cimo", "en":"Móveis Cimo (Cimo Furniture)", "es":"Muebles Cimo"}, link:"moveis", image:"menu-3.jpg"},        
        {id: 4, title: {"pt":"Brinquedos da Infância", "en":"My Childhood Toys", "es":"Juguetes de mi niñez"}, link:"brinquedos", image:"menu-4.jpg"},
        {id: 5, title: {"pt":"Sala de época", "en":"Period Classroom", "es":"Clase de época"}, link:"saladeepoca", image:"menu-5.jpg"},
        {id: 6, title: {"pt":"Maquete", "en":"3D Model", "es":"Maqueta 3D"}, link:"maquete", image:"menu-6.jpg"},
        {id: 7, title: {"pt":"Passado e Presente", "en":"Past and Present", "es":"Pasado y Presente"}, link:"passadoepresente", image:"menu-7.jpg"},
        {id: 8, title: {"pt":"Audioguia", "en":"Audioguide", "es":"Audioguía"}, link:"audioguia", image:"menu-8.jpg"},
    ]
    
    return (
        <>
            <div id="menu">
                <div id="itens-menu">
                    {itens.map(item=>(
                        <div className="item-menu" key={'menu-'+item.id} onClick={()=>setPage(item.link)}>
                            <img className="img-item-menu" src={`./content/menu/${item.image}`} alt={item.title}></img>
                            <div className="titulo-item-menu">{item.title[language]}</div>                                
                        </div>
                    ))}
                </div>        
            </div>

            <footer className="footer">
                <div>
                    <button className="btn mr" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home" /></button>
                </div> 
                <div>

                </div>
                <div style={{justifySelf: 'right', paddingRight:'40px'}}>
                    <button className="btn" onClick={()=>setPage('fichatecnica')}><FontAwesomeIcon icon="info" /></button>
                </div>                                  
            </footer>
        </>
    )   
}
 
export default Menu
