import React, { useRef, useEffect } from 'react'


function Piece({piece, image, onMouseDown, onTouchEnd, setSize, show, isTouch}){
    const gRef = useRef()

    useEffect(()=>{
        let {x, y, width, height} = gRef.current.getBoundingClientRect()
        setSize(piece.id, width, height, x, y)
    // eslint-disable-next-line
    }, [])

    return (
        <>  
            <g id={'g'+piece.id} transform={`translate(${piece.position.x} ${piece.position.y})`}
                onMouseDown={(e)=>{if(!isTouch){onMouseDown(piece, e.clientX, e.clientY-100)}   }} onTouchStart={(e)=>onMouseDown(piece, e.touches[0].clientX, e.touches[0].clientY-100)}  onTouchEnd={onTouchEnd}>
         
                <defs>
                    <clipPath id={'cp-'+piece.id} >
                        <path id={'path'+piece.id} ref={gRef}  d={piece.path}   stroke="green" strokeWidth="0" />
                    </clipPath> 
                </defs>
                
                {show && <image className="piece" id={'image-'+piece.id} href={`/content/predio/${image.imagem}`} x={piece.image.x} y={piece.image.y} width={image.width} height={image.height}
                      clipPath={`url(#cp-${piece.id})`}          /> }
                      {/* preserveAspectRatio="xMidYMid slice" */}
                {/* <text>active:{JSON.stringify(active)}</text> */}
            </g>
        </>
    )
}
 
export default Piece
