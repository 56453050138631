import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



import './styles.css'


function Audioguia({setTitle, language, setPage}){
    useEffect(()=>{
        let title = {"pt":"Audioguia", "en":"Audioguide", "es":"Audioguía"}
        setTitle(title[language])        
    // eslint-disable-next-line
    },[language])

    
    
    return (
        <>
            <div className="container-audioguia">
                <img className="qrcode" src="./content/audioguia/qrcode.svg" alt="audio guia"></img>
            </div>
            
            <footer className="footer">
                <div>
                    <button className="btn mr" onClick={()=>{setPage('home')}}><FontAwesomeIcon icon="home" /></button>
                    <button className="btn" onClick={()=>{setPage('menu')}}><FontAwesomeIcon icon="arrow-left" /></button>
                </div>                   
            </footer>  
        </>
    )
}
 
export default Audioguia
