import React, { useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'




import './styles.css'
import Themes from '../../content/memoria.json'
import Card from './Card'
import formataTempo from '../../utils/formataTempo'
import Lista from '../../components/Lista'
// import { text } from '@fortawesome/fontawesome-svg-core';
const uuid = require('uuid/v4')

const texts = {
    title:{
        pt:"Quebra Cabeça",
        en:"Jigsaw puzzle",
        es:"Rompecabeza",
    },
    newgame:{
        pt:"Novo jogo",
        en:"New game",
        es:"nuevo juego",
    },
    congratulations:{
        pt:"Parabéns!",
        en:"Congratulations!",
        es:"Felicidades!",
    },
    time:{
        pt:"Você finalizou o jogo em",
        en:"You finished the game in",
        es:"Terminaste el juego en",
    }
}

function shuffle(array) {    
    return array.sort(() => Math.random() - 0.5);
}

function generateCards(options, theme){
    let total = options.columns * options.rows
    let cards = []
    
    shuffle(theme.cards).slice(0,total/2).forEach(({name, image})=>{
        cards = cards.concat({
            id: uuid(),
            name,
            image,
            isFlipped:false
        },
        {
            id: uuid(),
            name,
            image,
            isFlipped:false
        })
    })
    
    return shuffle(cards)
}

function updateIsFlipped(cards, card, flipped){
    return cards.map(c=>{
        let isFlipped = c.isFlipped
        if(c.id===card.id){
            isFlipped=flipped
        }
        return {...c, isFlipped}
    })
}





function Memoria({setTitle, language, setPage}){
    const options = {
        columns:6,
        rows:3
    }
    const [theme, setTheme] = useState(null)
    const [cards, setCards] = useState([])
    const [first, setFirst] = useState(null)
    const [second, setSecond] = useState(null)
    const [canFlip, setCanFlip] = useState(true)
    const [elapsed, setElapsed] = useState(0)
    const [complete, setComplete] = useState(false) 
    let interval = useRef(null)

    useEffect(()=>{
        setTitle(texts.title[language])
    // eslint-disable-next-line
    },[language])

    useEffect(function(){
        let completed = cards.length>0 && cards.every(card => card.isFlipped)
        if(completed){
            clearInterval(interval.current)
            setComplete(true)
        }
    }, [cards])

    useEffect(themeSelected,[theme])

    function themeSelected(){
        if(theme){
            setElapsed(0)
            setComplete(false)
            interval.current = setInterval(() => {
                setElapsed(elapsed => elapsed + 1);
            }, 1000);    
            let cards = generateCards(options, theme)    
            // for (let i=0;i<17;i++){
            //     cards[i].isFlipped=true
            // }    
            setCards(cards)
        }        
    }

    useEffect(()=>{
        return () =>{
            clearInterval(interval.current)
        }
    },[])

    function newGame(){
        clearInterval(interval.current)
        setTheme(null)
        setElapsed(0)        
    }    

    function flipCard(card){
        if(canFlip){
            setCards(updateIsFlipped(cards, card, true))

            if(first===null){            
                setFirst(card)
                
            }else{
                setSecond(card)  
                setCanFlip(false)        
            }
        }        
    }
    
    const fieldStyle = {
        gridTemplateColumns: `repeat(${options.columns}, auto)`,
	    gridTemplateRows: `repeat(${options.rows}, 1fr)`
    }

    useEffect(()=>{
        if(first!==null && second!==null){
            if(first.name===second.name){
                setCanFlip(true)
                setFirst(null)
                setSecond(null)
            }
            else{
                setTimeout(() => {                  
                    let updatedCards = updateIsFlipped(cards, first, false)
                    setCards(updateIsFlipped(updatedCards, second, false))
                    setFirst(null)
                    setSecond(null)
                    setCanFlip(true)
                }, 1000);
     
            }
        }
        // eslint-disable-next-line 
    },[first, second]) 

    function selectTheme(){
        return (
            <Lista setTitle={setTitle} lista={{id:"memoria",nome:texts.title, itens:Themes.map(theme=>({...theme, arquivo:theme.image}))}} selecionar={setTheme} back="jogos" language={language} setPage={setPage}></Lista>
        )
    }
    
    function field(){
        return (
            <>  
                <div className="field center" style={fieldStyle}>
                    {cards.map(card=><Card key={card.id} card={card} flipCard={flipCard} theme={theme}></Card>)}
                </div>
                <footer className="footer">
                    <div>
                        <button className="btn mr" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home" /></button>
                        <button className="btn" onClick={()=>setPage('jogos')}><FontAwesomeIcon icon="arrow-left" /></button>
                    </div>
                    <div>                    
                        <button className="btn" onClick={newGame}>{texts.newgame[language]}</button>
                    </div>
                    <div className="tempo">
                        {formataTempo(elapsed)}
                    </div>
                </footer>           
            </>
        )
    }

    function end(){
        return (
            <>     
                <div className="content center end">                    
                    <h1>{texts.congratulations[language]}</h1> 
                    <h2>{texts.time[language]} {formataTempo(elapsed)}</h2>
                </div>
                <footer className="footer">
                    <div>
                        <button className="btn mr" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home" /></button>
                        <button className="btn" onClick={()=>setPage('jogos')}><FontAwesomeIcon icon="arrow-left" /></button>
                    </div>
                    <div>                    
                        <button className="btn" onClick={newGame}>{texts.newgame[language]}</button>
                    </div>                    
                </footer>          
            </>            
        )
    }
    
    if(theme && !complete){
        return field()
    }else if(theme && complete){
        return end()
    }else{
        return selectTheme()
    }   
}
 
export default Memoria
