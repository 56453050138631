function addZero(n){
    return n < 10 ? '0' + n : n;
}

const formataTempo = function(value){
    let second = value%60
    let minute = Math.floor(value%3600/60)
    let hour = Math.floor(value/3600)
    
    return `${hour>0?addZero(hour)+':':''}${addZero(minute)}:${addZero(second)}`
}

export default formataTempo