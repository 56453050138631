import React, { useEffect } from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import { Link } from "react-router-dom";

import './styles.css'
import Lista from '../../components/Lista'

const jogos = [
    {
        id:"quebra-cabeca",
        nome:{
            pt:"Quebra Cabeça",
            en:"Jigsaw puzzle",
            es:"Rompecabeza",
        },
        arquivo:"quebra-cabeca.jpg",
        link:"jogos-quebracabeca"
    },
    {
        id:"memoria",
        nome:{
            pt:"Jogo da Memória",
            en:"Memory Game",
            es:"Juego de Memoria",
        },
        arquivo:"jogo-memoria.jpg",
        link:"jogos-memoria"
    }

]

function Jogos({setTitle, language, setPage}){

    useEffect(()=>{
        let title = {
            pt:"Jogos",
            en:"Games",
            es:"Juegos"
        }
        setTitle(title[language])
        // eslint-disable-next-line
    },[language])

    
    return (
        <>
            <Lista setTitle={setTitle} lista={{id:"jogos",nome:"Jogo da Memória", itens:jogos}} link={true} back="menu" background={true} language={language} setPage={setPage}></Lista>
        </>
    )

   
}
 
export default Jogos
