

import React from 'react'

// import {
//     useHistory,
//   } from "react-router-dom";

  import './styles.css'

function Navbar({title, language, setLanguage, languages}){

    

    return (
        <>
            <header className="header">
                <div>
                <img className="logo" src="./content/logo.png" alt="MESC"></img>
                <div>
                    <h1 className="title-header">{title}</h1>
                    <div className="idiomas">
                        {languages.map(l => <img key={l.id} className={'img-idioma'+(l.id===language?' idioma-ativo':'')} src={'.'+l.image} alt={l.description} onClick={()=>{setLanguage(l.id)}}></img>)}                            
                    </div>
                    </div>
                </div>
            </header>
        </>
    )
}
 
export default Navbar
