import {useState, useEffect} from 'react'

const useResize = (myRef) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const handleResize = () => {
    if(myRef.current){
      setWidth(myRef.current.clientWidth)
      setHeight(myRef.current.clientHeight)
    }
  }

  useEffect(() => {
    if(myRef.current){
      setWidth(myRef.current.clientWidth)
      setHeight(myRef.current.clientHeight)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line
  }, [myRef.current])

  return { width, height }
}

export default useResize