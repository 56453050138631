import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import './styles.css'

function Descricao({setTitle, content, back, language, setPage}){
    const {id, nome, imagem, descricao} = content
    
    useEffect(()=>{
        setTitle(nome[language])  
    // eslint-disable-next-line
    },[language])


      
    return (
        <>        
            <div id="descricao-lista-2" style={{minHeight:0,maxHeight:'100%'}}>
                <img className="img-descricao-lista-2" src={'.'+imagem} alt={nome}></img>
                <div className="corpo-descricao-lista-2">

                    {descricao && descricao[language].map((element,index)=>(
                        <div key={`${id}-${index}`}>
                            {element.type==='t' && <h3>{element.value}</h3>}
                            {element.type==='p' && <p>{element.value}</p>}
                            {element.type==='l' && <div className="btn-descricao-lista-2"><button className="btn" onClick={()=>setPage(element.link)}>{element.value}</button></div>}
                            {element.type==='i' && <div className="img-corpo-descricao-lista-2"><img  src={'.'+element.src} alt={element.alt}/></div>}
                            
                        </div>
                    ))}  
                
                </div>
            </div> 

            
            <footer className="footer">
                <div>
                    <button className="btn mr" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home" /></button>
                    <button className="btn" onClick={()=>setPage(back)}><FontAwesomeIcon icon="arrow-left" /></button>
                </div>                   
            </footer>  
        </>
    )
     
    
    
}
 
export default Descricao



