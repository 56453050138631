import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



import './styles.css'

function Maquete({setTitle, language, setPage}){
    const [model, setModel] = useState(null)

    const pavimentos = [
        {id:"externo", name:{pt:"Vista externa",en:"External view",es:"Vista externa"}, file:"./content/maquete/geral/index.htm"},
        {id:"terreo", name:{pt:"Térreo",en:"Ground Floor",es:"Piso inferior"}, file:"./content/maquete/terreo/index.htm"},
        {id:"superior", name:{pt:"1º Andar",en:"1st floor",es:"Primer Piso"}, file:"./content/maquete/superior/index.htm"}      
    ]

    useEffect(()=>{              
        setModel(pavimentos[0])
    // eslint-disable-next-line
    },[])

    useEffect(()=>{
        let title = {
            pt:"Maquete", 
            en:"3D Model", 
            es:"Maqueta 3D"
        }
        setTitle(title[language])        
    // eslint-disable-next-line
    },[language])
    
    return (
        <>
            <div className="container">
                {model && <iframe title="Maquete"  src={'.'+model.file}></iframe>} 
                <div id="menu-maquete">
                    {pavimentos.map(pavimento => <button key={pavimento.id} className={'btn' + ((model && model.id===pavimento.id)?' ativo':'')} onClick={()=>setModel(pavimento)} >{pavimento.name[language]}</button>)}                    
                </div>
            </div>
            
            <footer className="footer">
                <div>
                    <button className="btn mr" onClick={()=>{setPage('home')}}><FontAwesomeIcon icon="home" /></button>
                    <button className="btn" onClick={()=>{setPage('menu')}}><FontAwesomeIcon icon="arrow-left" /></button>
                </div>                   
            </footer>  
        </>
    )
}
 
export default Maquete
