const uuid = require('uuid/v4')

function getPointsLine(rotate){
    // console.log('rotate', rotate)
    if(rotate===false){
        return [
            {x:0,y:0},
            {x:1.0,y:0}
        ]
    }else{
        return [
            {x:0,y:0},
            {x:0,y:1}
        ]
    }
}

function edgeDistributions(rotate) {
    var randomBetween = function(min, max) {
      return Math.random() * (max - min) + min;
    };
  
    // These are good regions in which to displace control points
    // at the bottom of the knob
    var baselineOffsets = {
      xMin: 49,  //51
      xMax: 62, //62
      yMin: 0, //0
      yMax: 2 //5
    };
  
    // These are good regions in which to displace control points
    // at the top of the knob
    var upperOffsets = {
      xMin: 20, //20
      xMax: 30, //30
      yMin: 20, //20
      yMax: 35  //44
    };
  
    var point1 = [0, 0];
    var point2 = [
    randomBetween(baselineOffsets.xMin, baselineOffsets.xMax),
    randomBetween(baselineOffsets.yMin, baselineOffsets.yMax)
    ];
    var point3 = [
    randomBetween(upperOffsets.xMin, upperOffsets.xMax),
    randomBetween(upperOffsets.yMin, upperOffsets.yMax)
    ];
    var point4 = [
    randomBetween(100 - upperOffsets.xMax, 100 - upperOffsets.xMin),
    randomBetween(upperOffsets.yMin, upperOffsets.yMax)
    ];
    var point5 = [
    randomBetween(100 - baselineOffsets.xMax, 100 - baselineOffsets.xMin),
    randomBetween(baselineOffsets.yMin, baselineOffsets.yMax)
    ];
    var point6 = [100, 0];

    var points = [point1, point2, point3, point4, point5, point6];

    // Choose whether to point inward or outward
    var sign = Math.random() < 0.5 ? -1 : 1;
    if(rotate===false){
        return points.map( point => (
            {
                x: (point[0] / 100), 
                y: (point[1] * sign / 100)
            })
        )
    }else{
        return points.map( point => (
            {
                y: (point[0] / 100), 
                x: (point[1] * sign / 100)
            })
        )
    }
    
}

function getEdge(dx,dy,rotate=false, line=false){
    let origin = {
        x: 0,
        y: 0
    }

    let points;

    points = line ? getPointsLine(rotate) : edgeDistributions(rotate)

    points.forEach(point=>{
        point.x*=dx
        point.y*=dy
    })

    // points.forEach(point=>{
    //     point.x+=origin.x
    //     point.y+=origin.y
    // })

    return {
        origin,
        points
    }
}

function getEdges(options, width, height){
    let {rows, columns} = options
    let vertical = []
    let horizontal = []

    let dx = (width/columns) || 1
    let dy = (height/rows) || 1

    // horizontal
    for(let i=0; i<(rows+1);i++){
        let row = []
        for(let j=0; j<columns; j++){
            let line = (i===0 || i===rows)
            row.push(getEdge(dx,dy,false, line))
        }     
        horizontal.push(row)   
    }

    // vertical
    for(let i=0; i<rows;i++){
        let row = []
        for(let j=0; j<(columns+1); j++){
            let line = j===0 || j===columns
            row.push(getEdge(dx,dy,true, line))
        }
        vertical.push(row)
    }

    return {horizontal, vertical}
}

function getEdge2(points){
    let [p1, p2] = points    
    return ` ${p1.x} ${p1.y} L ${p2.x} ${p2.y}` 
}

function getPath(points, first){
    let [p1,p2,p3,p4,p5,p6] = points

    let aux1 = {x:(p1.x+p2.x)/2, y:(p1.y+p2.y)/2}
    let aux2 = {x:(p2.x+p3.x)/2, y:(p2.y+p3.y)/2}
    let aux3 = {x:(p3.x+p4.x)/2, y:(p3.y+p4.y)/2}
    let aux4 = {x:(p4.x+p5.x)/2, y:(p4.y+p5.y)/2}
    let aux5 = {x:(p5.x+p6.x)/2, y:(p5.y+p6.y)/2}
    
    return ` ${p1.x} ${p1.y} 
    L ${aux1.x} ${aux1.y}
    C ${aux1.x} ${aux1.y} ${p2.x} ${p2.y} ${aux2.x} ${aux2.y}
    C ${aux2.x} ${aux2.y} ${p3.x} ${p3.y} ${aux3.x} ${aux3.y}
    C ${aux3.x} ${aux3.y} ${p4.x} ${p4.y} ${aux4.x} ${aux4.y}
    C ${aux4.x} ${aux4.y} ${p5.x} ${p5.y} ${aux5.x} ${aux5.y}
    L ${p6.x} ${p6.y}`
}

function getPiecePath(edges){

    let [edge1, edge2, edge3, edge4] = edges

    let path1 = edge1.points.length===2 ? getEdge2(edge1.points) : getPath(edge1.points)
    let path2 = edge2.points.length===2 ? getEdge2(edge2.points) : getPath(edge2.points)
    let path3 = edge3.points.length===2 ? getEdge2(edge3.points.reverse()) : getPath(edge3.points.reverse())
    let path4 = edge4.points.length===2 ? getEdge2(edge4.points.reverse()) : getPath(edge4.points.reverse())

    return `M ${path1} L ${path2} L ${path3} L ${path4} Z`
}

function getPieces(options, image){
    let {horizontal, vertical} = getEdges(options, image.width, image.height)

    let {rows, columns} = options
    let dx = (image.width/columns) || 0
    let dy = (image.height/rows) || 0

    let pieces = []
    for( let i=0; i<rows; i++){
        for (let j=0; j<columns; j++){

            let position ={
                x: 0, 
                y: 0
            }
            let image={
                x:-j*dx, 
                y:-i*dy
            }
            
            let e1 = JSON.parse(JSON.stringify(horizontal[i][j]))
            let e2 = JSON.parse(JSON.stringify(vertical[i][j+1]))
            let e3 = JSON.parse(JSON.stringify(horizontal[i+1][j]))         
            let e4 = JSON.parse(JSON.stringify(vertical[i][j]))

            e2.points.forEach(point=>point.x+=dx)
            e3.points.forEach(point=>point.y+=dy)

            let edges = [e1,e2,e3,e4]

            let path = getPiecePath(edges)
            
            let piece = {
                id: uuid(),
                i, j, dx, dy,
                position,
                image,                
                path,
                width:0,
                height:0,
                final:position
            }
            pieces.push(piece)
        }
    }

    pieces.forEach(piece=>{
        let {i,j} = piece
        let horizontal = [{i,j:j-1},{i,j:j+1}]
        let vertical = [{i:i-1,j},{i:i+1,j},]
        piece.proximo = {
            horizontal: pieces.filter(p=>horizontal.some(ij=>p.i===ij.i && p.j===ij.j)).map(p=>p.id),
            vertical: pieces.filter(p=>vertical.some(ij=>p.i===ij.i && p.j===ij.j)).map(p=>p.id)
        }
    })

    return pieces
}

export default getPieces