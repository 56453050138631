import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.css'

function FichaTecnica({setTitle, language, back, setPage}){
    
    useEffect(()=>{
        setTitle('Ficha Técnica')  
    // eslint-disable-next-line
    },[language])


      
    return (
        <>        
            <div id="container-ficha-tecnica" style={{minHeight:0,maxHeight:'100%'}}>
                <div className="ficha-tecnica">
                    <div className="item-ficha-tecnica">
                        <div className="texto-item-ficha-tecnica">
                            <p>
                                <strong>Universidade do Estado de Santa Catarina - UDESC</strong>
                                <br/>Gestão 2016-2020
                                <br/>Reitor - Prof. Marcus Tomasi
                                <br/>Vice-Reitor - Prof. Leandro Zvirtes
                            </p>
                            <p>
                                <strong>Organizadoras</strong>
                                <br/>Prof. Sandra Makowiecky
                                <br/>Prof. Beatriz Goudard
                            </p>
                        </div>
                        <div className="img-ficha-tecnica">
                            <img  src="./content/mesc_udesc_2.jpg" alt="Tour Virtual 360"></img>
                        </div>
                    </div>
                    <div className="item-ficha-tecnica">
                        <div className="texto-item-ficha-tecnica">
                            <p>
                                <strong>Fotos panorâmicas ambientes</strong>
                                <br/>Ricardo Pedrosa Macedo
                            </p>

                            <p>
                                <strong>Fotos brinquedos, miniaturas Móveis Cimo e ambientes</strong>                            
                                <br/>Ricardo Pedrosa Macedo
                            </p>

                            <p>
                                <strong>Organização do conteúdo e layout</strong>          
                                <br/>Cristiane Pedrosa Macedo                      
                            </p>                            

                            <p>
                                <strong>Tour virtual e audioguia</strong>
                                <br/>Cristiane Pedrosa Macedo
                            </p>

                            <p>
                                <strong>Desenvolvimento</strong>
                                <br/>Nícolas Pedrosa Macedo
                            </p>

                            <p>
                                <strong>Vídeo Passado e Presente</strong>
                                <br/>Ricardo Pedrosa Macedo
                                <br/>Guilherme Marcos Ferreira
                            </p>
                            


                        </div>
                        <div className="img-ficha-tecnica tv-360">
                            <img  src="./content/TOUR_360_reduzido2.png" alt="Tour Virtual 360"></img>
                            <p>www.tourvirtual360.com.br</p>
                        </div>
                    </div>
                    <div className="item-ficha-tecnica">
                        <div className="texto-item-ficha-tecnica">
                            <p>
                                <strong>Desenho Maquete 3D</strong>
                                <br/>Cláudio Aparecido da Silva
                            </p>

                            <p>
                                <strong>Tradução Inglês</strong>
                                <br/>Lily Theodoro Lu Vaz
                            </p>

                            <p>
                                <strong>Tradução Espanhol</strong>
                                <br/>Luz Maria Teresa Romero Silva
                            </p>

                            <p>
                                <strong>Locução Inglês</strong>
                                <br/>Douglas Zanon
                            </p>

                            <p>
                                <strong>Locução Espanhol</strong>
                                <br/>Jorge Daniel Fernandez Martinez
                            </p>

                            <p>
                                <strong>Libras</strong>
                                <br/>Cepol – Centro de Ensino de Português e Libras
                            </p>

                            <p>
                                <strong>Fotos ambientes</strong>
                                <br/>Carlos Pontalti
                            </p>

                        </div>
                        <div></div>
                    </div>
                </div>
            </div> 

            
            <footer className="footer">
                <div>
                    <button className="btn mr" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home" /></button>
                    <button className="btn" onClick={()=>setPage(back)}><FontAwesomeIcon icon="arrow-left" /></button>
                </div>                  
            </footer>  
        </>
    )
     
    
    
}
 
export default FichaTecnica



