import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



import './styles.css'
import { useRef } from 'react';

function PassadoPresente({setTitle, language, setPage}){
    const video = useRef()
    useEffect(()=>{
        let title = {
            pt:"Passado e Presente", 
            en:"Past and Present", 
            es:"Pasado y Presente"
        }
        setTitle(title[language])        
    // eslint-disable-next-line
    },[language])

    useEffect(()=>{
        video.current.play()
    },[])
    
    return (
        <>
            <div className="container-video">
                <video ref={video} style={{maxWidth:'100%', maxHeight:'100%'}} type="video/mp4" controls src="./content/passado-e-presente/mesc_video.mp4"></video>
            </div>
            
            <footer className="footer">
                <div>
                    <button className="btn mr" onClick={()=>{setPage('home')}}><FontAwesomeIcon icon="home" /></button>
                    <button className="btn" onClick={()=>{setPage('menu')}}><FontAwesomeIcon icon="arrow-left" /></button>
                </div>                   
            </footer>  
        </>
    )
}
 
export default PassadoPresente
