import React, { useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



import './styles.css'


function Tour({setTitle, language, setPage}){
    const [libras, setLibras] = useState(false)

    const src = useMemo(()=>{
        let src = ""
        switch (language) {
            case 'pt':
                src = libras ? '/content/tour/libras/index.htm' : '/content/tour/pt/index.htm';
                break;
            case 'en':
                src = '/content/tour/en/index.htm';
                break;
            case 'es':
                src = '/content/tour/es/index.htm'; 
                break;  
            default:
                break;            
        }
        return src
    },[language, libras])

    useEffect(()=>{
        setTitle("Tour Virtual")        
    // eslint-disable-next-line
    },[])

    
    return (
        <>
            <div className="container">
                <iframe title="Tour"  src={'.'+src}></iframe>
            </div>
            
            <footer className="footer">
                <div>
                    <button className="btn mr" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home"/></button>
                    <button className="btn" onClick={()=>setPage('menu')}><FontAwesomeIcon icon="arrow-left" /></button>
                </div> 
                <div></div>    
                <div>
                    {language==='pt' && <img src="./content/libras.png" className="icone-libras" alt="Tour em Libras" onClick={()=>setLibras(!libras)}></img>}
                </div>              
            </footer>  
        </>
    )
}
 
export default Tour
