import React, { useEffect, useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'




import './styles.css'

function Lista({setTitle, lista, back, selecionar, link, height, background, language, setPage}){
    // console.log(language, lista.itens.map(item => item))
    const showDescription = useMemo(()=>{
        return lista.itens.some(item => (item.nome[language] && item.nome[language]!==''))
    }, [lista, language])
    useEffect(()=>{
        setTitle(lista.nome[language])
    // eslint-disable-next-line
    },[language])

    function styleItemLista(){
        let style = {
            cursor: (selecionar||link)?'pointer':'default',
            // gridTemplateRows: showDescription?'1fr 220px':'1fr'
        }

        if(!height){
            // style['width'] = '455px'
        }

        return style
    }

    function styleImgItemLista(){
        let style = {
            // height: height?height:'455px',
        }

        if(background){
            style['backgroundColor'] = 'white'
            style['padding'] ='10px'
        }

        return style
    }

    const render ={
        lista(){
            return (
                <>
                    <div id="lista">                        
                    {/* {gridTemplateColumns: `repeat( ${lista.itens.length}, 1fr)`} */}
                        <div id="itens-lista" > 
                            {lista.itens.map(item=>{
                                if(link){
                                    return (
                                        <div key={item.id} className={`item-lista ${showDescription?'com-descricao':''}`} style={styleItemLista()} onClick={()=>{setPage(item.link)}}>
                                            <img className="img-item-lista" style={styleImgItemLista()} src={`./content/${lista.id}/${item.arquivo}`} alt={item.nome[language]}></img>
                                            {showDescription && (<div className="descricao-item-lista">
                                                <span className="titulo-item-lista">{item.nome[language]}</span>
                                                <p className="texto-descricao-item-lista">{item.descricao && item.descricao[language]}</p>
                                            </div>)}
                                        </div> 
                                )
                                }else{
                                    return (
                                        <div key={item.id} className={`item-lista ${showDescription?'com-descricao':''}`} style={styleItemLista()} onClick={()=>{if(selecionar){selecionar(item)}}}>
                                            <img className="img-item-lista" style={styleImgItemLista()} src={`./content/${lista.id}/${item.arquivo}`} alt={item.nome[language]}></img>                                            
                                            {showDescription && (<div className="descricao-item-lista">
                                                <span className="titulo-item-lista">{item.nome[language]}</span>
                                                <p className="texto-descricao-item-lista">{item.descricao && item.descricao[language]}</p>
                                            </div>)}
                                        </div> 
                                    )
                                }
                            }
                                                           
                            )}
                        </div>
                    </div>
                    
                    <footer className="footer">
                        <div>
                            <button className="btn mr" onClick={()=>setPage('home')}><FontAwesomeIcon icon="home" /></button>
                            {back && <button className="btn" onClick={()=>setPage(back)}><FontAwesomeIcon icon="arrow-left" /></button>}
                        </div>                   
                    </footer>  
                </>
            )
        }      
    }
    
    return render.lista()
}
 
export default Lista
