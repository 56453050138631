import React from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.css'

import Slideshow from 'react-slidez'


import { useEffect } from 'react';

function Inicio({setTitle, setShowNav, language, setLanguage, languages, setPage}){
    const images = [
        {id: 1, url:"./content/home/home-1.jpg"},
        {id: 2, url:"./content/home/home-2.jpg"},
        {id: 3, url:"./content/home/home-3.jpg"},
        {id: 4, url:"./content/home/home-4.jpg"},
        {id: 5, url:"./content/home/home-5.jpg"},
        {id: 6, url:"./content/home/home-6.jpg"},
        {id: 7, url:"./content/home/home-7.jpg"},
        {id: 8, url:"./content/home/home-8.jpg"}
    ]

    useEffect(()=>{
        setShowNav(false)
        return ()=>{setShowNav(true)}
        // eslint-disable-next-line
    },[])

    const itens = [
        {
            id:"conheca",
            link:"menu",
            title:{
                pt:"Conheça o museu",
                en:"Discover the Museum",
                es:"Conozca el Museo"
            }
        },
        {
            id:"jogos",
            link:"jogos",
            title:{
                pt:"Jogos",
                en:"Games",
                es:"Juegos"
            }
        }
    ]
    
    return (
        <>
            <div id="home">
                <Slideshow
                    showIndex={false}
                    showArrows={false}
                    autoplay
                    enableKeyboard={false}
                    useDotIndex
                    slideInterval={10000}
                    defaultIndex={1}
                    slides={images.map(image=>image.url)}
                    effect={'fade'}
                    height={'100%'}
                    width={'100%'}
                />
                
                <div className="overlay"></div>
                <div className="logo-home">
                    <img className="img-logo-home" src="./content/logo-branco.png" alt="MESC"></img>
                    <div className="menu-home">
                        {itens.map(item => <button key={item.id} className="btn-home" onClick={()=>setPage(item.link)}>{item.title[language]}</button>)}
                    </div>
                </div>

                <div style={{position:"fixed", bottom:0, right:0, zIndex:3, padding:'10px'}} className="idiomas" >
                    {languages.map(l => <img key={l.id} className={'img-idioma'+(l.id===language?' idioma-ativo':'')} src={'.'+l.image} alt={l.description} onClick={()=>{setLanguage(l.id)}}></img>)}                            
                </div>
            </div>
        </>
    )

   
}
 
export default Inicio
